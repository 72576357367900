import axios from "@/libs/axios";
import constants from "@/constants";

export default {
  async fetchUserDetailsByToken(_, token) {
    const res = await axios.get(`${constants.baseApiUrl}/user/${token}`);
    return res.data;
  },
  async getOrgUsers(_, payload) {
    const users = await axios.get(
      `https://platform.getboarded.com/api/employees/${payload.gborgid}`
    );
    return users.data;
  },
  async userStatus(_, payload) {
    const userStatus = await axios.get(
      `${constants.baseApiUrl}/organization/employee/${payload.gborgid}/${payload.gbuid}`
    );
    return userStatus.data;
  },
  async getSegmentedUser(_, payload) {
    const segmentedUser = await axios.post(
      `${constants.baseApiUrl}/organization/employees/segment/${payload.gtbrddOrganizationId}`,
      payload
    );
    return segmentedUser.data;
  },
  async getUserExtraCurricular(_, payload) {
    const res = await axios.get(`${constants.apiHost}/extra-curricular`, {
      params: { userId: payload },
    });
    return res.data;
  },
  async fetchUserAppSubscriptions({ commit }, payload) {
    const resObj = await axios.get(
      `${constants.baseApiUrl}/user/app-subscriptions/${payload}`
    );
    delete resObj.data.data["alex"];
    commit("SET_USER_APP_SUBSCRIPTIONS", resObj.data.data);
    return resObj.data.data;
  },
  async fetchOrganizationDetails(_, orgId) {
    const resObj = await axios.get(
      `${constants.baseApiUrl}/organization/${orgId}`
    );
    return resObj;
  },
  async fetchOrganizationById(_, _id) {
    const resObj = await axios.get(`${constants.apiHost}/organization/${_id}`);
    return resObj.data;
  },
  // async searchUserLibraryItems(_, payload) {
  //   const resObj = await axios.post(
  //     `${constants.baseApiUrl}/alex/library/library-items/filter/user`,
  //     payload
  //   );
  //   return resObj.data;
  // },
  // Skills
  async updateUserSkills(_, payload) {
    const resObj = await axios.post(
      `${constants.journeyApiUrl}/skills`,
      payload
    );
    return resObj.data;
  },
  async fetchUserSkills(_, payload) {
    const resObj = await axios.get(
      `${constants.journeyApiUrl}/skills/${payload.orgId}/${payload.userId}
        `
    );
    return resObj.data;
  },
};
