import Vue from "vue";

// axios
import axios from "axios";
// const accessToken = localStorage.getItem("accessToken");

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'Authorization':  `Bearer ${accessToken}`}
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
