export default {
  OPEN_CHAT_BOT_POPUP(state, value) {
    state.chatBotPopup = value;
  },
  OPEN_DISTRIBUTE_COURSE_POPUP(state, value) {
    state.distributeCoursePopup = value;
  },
  OPEN_EDITOR_POPUP(state, value) {
    state.editorPopup = value;
  },
  OPEN_REPORT_POPUP(state, value) {
    state.talentReportPopup = value;
  },
  OPEN_FILTER_POPUP(state, value) {
    state.filterPopup = value;
  },
  OPEN_SEARCH_POPUP(state, value) {
    state.searchPopup = value;
  },
  OPEN_ADD_CUSTOM_COURSE_POPUP(state, value) {
    state.addCustomCoursePopup = value;
  },
};
