const constants = {
  url: process.env.VUE_APP_url,
  s3Bucket: process.env.VUE_APP_s3Bucket,
  loginServiceUrl: process.env.VUE_APP_loginServiceUrl,
  editorUrl: process.env.VUE_APP_editorUrl,
  apiHost: process.env.VUE_APP_apiHost,
  libraryUrl: process.env.VUE_APP_baseApiUrl + "/alex/library",
  journeyApiUrl: process.env.VUE_APP_baseApiUrl + '/journey/integration',
  baseApiUrl: process.env.VUE_APP_baseApiUrl,
  feedApiUrl :process.env.VUE_APP_feedApiUrl,
  chatBotUrl: process.env.VUE_APP_CHATBOT_URL,
};

export default constants;
