export default {
  UPDATE_DISTRIBUTED_ITEM_LIST(state, value) {
    const index = state.selectedItem.indexOf(value);
    if (index > -1) {
      state.selectedItem.splice(index, 1);
    } else {
      state.selectedItem.push(value);
    }
  },
  EMPTY_SELECTED_ITEM(state) {
    state.selectedItem = [];
  },
  UPDATE_SEARCH_ITEM_QUERY(state, value) {
    state.searchItemQuery = value;
  },
  SELECT_FILTERS(state, value) {
    state.selectedFilter = value;
  },
  SELECT_ITEM_INSTRUCTIONS(state, value) {
    state.itemInstructions = value;
  },
};
