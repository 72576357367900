import { getUserData } from "@/auth/utils";
export default {
  userData: getUserData(),
  openiframe: true,
  avatarNameGenerator: (f, l) => {
    if (f && l) {
      return `${f.charAt(0)}%20${l.charAt(0)}`;
    } else if (f) {
      return `${f.charAt(0)}%20`;
    } else {
      return `S%20G`;
    }
  },
  appView: "userView",
  userAccessStatus: "user",
  appSubscriptions: {},
  reportUrl: "",
  activeOrgData: {
    orgSlug: "default",
    orgColor: "#e82b51",
  },
};
