export default {
  SET_USER(state, value) {
    state.data = value;
  },
  OPEN_IFRAME(state, value) {
    state.openiframe = value;
  },
  SET_USER_VIEW(state, value) {
    state.appView = value;
  },
  SET_USER_ACCESS_STATUS(state, value) {
    state.userAccessStatus = value;
  },
  SET_USER_APP_SUBSCRIPTIONS(state, value) {
    state.appSubscriptions = value;
  },
  SET_REPORT_URL(state, value) {
    state.reportUrl = value;
  },
  SET_ACTIVE_ORG_DATA(state, value) {
    state.activeOrgData = value;
  },
};
