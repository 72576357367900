import axios from "@/libs/axios";
import constants from "@/constants";

export default {
  async getUserLibraryItems(_, payload) {
    const libraryItems = await axios.get(
      `${constants.libraryUrl}/library-items/user/${payload.gborgid}/${payload.gbuid}`
    );
    return libraryItems.data;
  },
  async getOrgLibraryItems(_, payload) {
    const libraryItems = await axios.get(
      `${constants.libraryUrl}/library-items/organization/${payload.gborgid}`
    );
    return libraryItems.data;
  },
  // altf events
  async getCustomOrgLibraryItems(_, payload) {
    const libraryItems = await axios.get(
      `${constants.libraryUrl}/library-item-group/${payload.gborgid}`
    );
    return { data: libraryItems.data.data[0].subLibraryItems };
  },
  async getSegments(_, payload) {
    const segments = await axios.get(
      `${constants.libraryUrl}/segments/${payload.gborgid}`
    );
    return segments.data;
  },
  // distribute items to user by admin
  async distributeOrgItems(_, payload) {
    const response = await axios.post(
      `${constants.libraryUrl}/assignment/segment`,
      payload
    );
    return response.data;
  },
  //analytics
  async postItemAnalytics(_, payload) {
    const response = await axios.post(
      `${constants.libraryUrl}/analytics/library-item-views`,
      payload
    );
    return response.data;
  },
  async distributeUserItems(_, payload) {
    const response = await axios.post(
      `${constants.libraryUrl}/assignment/user`,
      payload
    );
    return response.data;
  },
  async getItemById(_, payload) {
    const item = await axios.get(
      `${constants.feedApiUrl}/getitemdetails?itemId=${payload.itemId}&type=${payload.type}&getboardedId=${payload.gbuid}`
    );
    return item.data;
  },
  async getCustomItemById(_, payload) {
    const item = await axios.get(
      `${constants.libraryUrl}/library-item-group/sub-item/${payload.gborgid}/${payload.gbuid}/${payload.itemId}`
    );
    return item.data;
  },

  async updateItemStatus(_, payload) {
    const response = await axios.put(
      `${constants.feedApiUrl}/updateitem`,
      payload
    );
    return response.data;
  },
  async getSources() {
    const response = await axios.get(`${constants.feedApiUrl}/getsourcelist`);
    return response.data;
  },
  async searchOrgLibraryItems(_, payload) {
    const response = await axios.get(
      `${constants.feedApiUrl}/librarykeywordsearch?keywords=${payload.query}`
    );
    return response.data;
  },
  async searchUserLibraryItems(_, payload) {
    const response = await axios.get(
      `${constants.feedApiUrl}/userlibrarykeywordsearch?keywords=${payload.query}&getboardedId=${payload.userId}`
    );
    return response.data;
  },
  async fetchUserEvents(_, payload) {
    const response = await axios.get(
      `${constants.feedApiUrl}/geteventrecommendations?getboardedId=${payload.gbuid}&library=true`
    );
    return response.data;
  },
  async getEvents() {
    const response = await axios.get(`${constants.feedApiUrl}/getallevents`);
    return response.data;
  },
  async uploadPicture(_, payload) {
    const response = await axios.post(
      `${constants.libraryUrl}/assignment/upload-library-item-thumbnail`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: false,
      }
    );
    return response.data;
  },
  async distributeCustomCourse(_, payload) {
    const response = await axios.post(
      `${constants.libraryUrl}/assignment/custom-library-item`,
      payload
    );
    return response.data;
  },
};
