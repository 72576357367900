import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/pages/Landing.vue"),
      meta: {
        pageTitle: "Home",
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
        layout: "full",
      },
    },
    {
      path: "/library-not-generated",
      name: "no-skills",
      component: () => import("@/views/pages/NoSkills.vue"),
      meta: {
        pageTitle: "Library",
        layout: "full",
      },
    },
    // {
    //   path: "/Home2",
    //   name: "Home2",
    //   component: () => import("@/views/Home2.vue"),
    //   meta: {
    //     pageTitle: "Home2",
    //     layout: "full",
    //   },
    // },
    // {
    //   path: "/bot",
    //   name: "chatbot",
    //   component: () => import("@/views/chatbot/Chatbot.vue"),
    //   meta: {
    //     layout: "full",
    //   },
    // },
    {
      path: "/library",
      name: "library",
      component: () => import("@/views/pages/Home.vue"),
      meta: {
        pageTitle: "Library",
        layout: "full",
      },
      beforeEnter(to, from, next) {
        // Proceed with the route update
        if (!to.query.gbuid || !to.query.gborgid || !to.query.org) {
          next({
            name: "error-404",
          });
        } else {
          next();
        }
      },
    },
    {
      path: "/skills",
      name: "skills",
      component: () => import("@/views/pages/Skills.vue"),
      meta: {
        pageTitle: "Skills",
        layout: "full",
      },
      beforeEnter(to, from, next) {
        // Proceed with the route update
        if (!to.query.gbuid || !to.query.gborgid || !to.query.org) {
          next({
            name: "error-404",
          });
        } else {
          next();
        }
      },
    },
    {
      path: "/redirect-page",
      name: "Redirect Page",
      component: () => import("@/views/pages/RedirectPage.vue"),
      meta: { pageTitle: "Redirect Page", layout: "full" },
      beforeEnter(to, from, next) {
        // Proceed with the route update
        if (
          !to.query.itemId ||
          !to.query.gbuid ||
          !to.query.gborgid ||
          !to.query.type
        ) {
          next({
            name: "error-404",
          });
        } else {
          next();
        }
      },
    },
    // {
    //   path: "/login",
    //   name: "login",
    //   component: () => import("@/views/Login.vue"),
    //   meta: {
    //     layout: "full",
    //   },
    // },
    {
      path: "/oauth-callback",
      name: "oauth-callback",
      component: () => import("@/auth/OAuthCallback.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  // Set Page Title
  const DEFAULT_TITLE = "Library";
  Vue.nextTick(() => {
    document.title = to.meta.pageTitle || DEFAULT_TITLE;
  });

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
